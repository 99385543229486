<template>
  <div class="not-found">
      <router-link to="/" class="error-code">
        <!--<img src="/images/slowpoke.png" />-->
        <div>404</div>
      </router-link>
    <div class="text">The page not found</div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>

<style lang="scss" scoped>
  .not-found {
    padding-top: 100px;
    text-align: center;
  }

  .error-code{
    color: $black;
    font-size: 100px;
  }

  .text{
    margin-top: 15px;
    font-size: 22px;
  }

  img {
    width: 150px;
    height: 150px;
  }
</style>